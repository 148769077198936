import { MainLayout } from "@/layouts";
import React from "react";
import WhyPeepSvg from "@/assets/why-peep.svg";
import styled from "styled-components";

const WhyPeepImage = styled.img`
	max-width: 300px;
	width: 100%;
`;

function Why() {
	return (
		<MainLayout navbar={true}>
			<h1 className="text-3xl md:text-4xl font-bold mt-4">¿Por qué Realpublik Management?</h1>
			<div className="grid grid-cols-1 md:grid-cols-2 mt-8 mb-16">
				<div className="flex justify-center">
					<WhyPeepImage src={WhyPeepSvg} alt="Why Peep" />
				</div>
				<div>
					<h2 className="text-xl font-bold">Es un espacio controlado para que vivas y sientas en carne propia lo que es ser un directivo</h2>
					<p className="mt-4">
						En la formación en gestión pública, adquirimos conceptos y criterios que nos ayudan a mejorar nuestro desempeño como Directivos Públicos. Muchos de los conceptos y modelos que aprendemos en aulas asumen como supuesto
						i) influencia al interior de la organización, ii) alineamiento y iii) cooperación entre las diferentes organizaciones públicas.
					</p>
					<p className="mt-4">
						No obstante, la experiencia que tienen los alumnos egresados es que lo aprendido no se puede aplicar en el Estado, pues dichos supuestos no se dan, llegando a referir un sector caótico, desorganizado y muchas veces
						corrupto, que no permite la aplicación de herramientas “teóricas”. Queda en no pocos casos la sensación de que “aprendimos todo aquello que no podremos implementar nunca”.
					</p>
					<p className="mt-4">
						En mi opinión informada y verificada en la experiencia, un Directivo Público exitoso es quien justamente hace que los supuestos anteriores se logren. Si bien un curso de habilidades directivas y algunas dinámicas
						grupales ayudan a desarrollar competencias, se requiere también de un espacio de entrenamiento que recree esa complejidad de actores e intereses del sector público y que exige una visión sistémica, multidimensional,
						por turnos, de trabajar en equipo y de influir y negociar con otros agentes para crecer en el largo plazo, sin sufrir en el corto. Asimismo, se requiere activar el trabajo con las emociones, controlando las propias e
						influyendo en las de los demás. Esto se aprende en los logros conjuntos pero también saboreando la derrota, la traición o la escasez y la incertidumbre.
					</p>
					<p className="mt-4">
						Realpublik Management llena ese vacío entre lo que aprendemos en la aulas y en la realidad de nuestros trabajos directivos en el sector público. En este juego de roles te acercarás a la realidad de los problemas y
						decisiones que deben tomar los directivos en el sector público, interiorizarás aprendizajes que te harán cuestionar conocimientos previos y que te permitirán actuar más efectivamente en la vida directiva. También
						aprenderás a conocerte a ti mismo para mejorar la construcción de tu figura directiva, esa figura que impactará en la vida de muchas personas.
					</p>
					<p className="mt-4 text-right font-bold">Alex Salas Kirchhausen</p>
				</div>
			</div>
		</MainLayout>
	);
}

export default Why;
